<template>
  <div
    class="dis_flex bg_col_fff"
    style="
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    "
  >
    <!-- 项 -->
    <div
      v-for="(item, index) in tabArr"
      :key="index"
      class="flex_1 dis_flex flex_dir_col ali_it_cen p_8_0"
      @click="itemClick(item)"
    >
      <img
        class="w_24 h_24"
        :src="
          currentRoutePath == item.path ? item.selectedIconUrl : item.iconUrl
        "
      />

      <div class="font_10 m_t_4">{{ item.label }}</div>
    </div>

    <!--  -->
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      currentRoutePath: null,

      //
    };
  },
  computed: {
    tabArr: () => [
      {
        label: '首页',
        iconUrl:
          'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/qdfcQVdWOLwDfUBgjEMt.png',
        selectedIconUrl:
          'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/qRJvkvAZdgOLYLwegrtK.png',
        path: '/index',
      },
      {
        label: '转账',
        iconUrl:
          'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/xzatdNvQIYKahFytdhXo.png',
        selectedIconUrl:
          'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/FQGzicpSXTpwxkoiFPGX.png',
        path: '/transfer',
      },
      {
        label: '银行卡',
        iconUrl:
          'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/IXcdIIEmSoIVOVettWBS.png',
        selectedIconUrl:
          'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/euZHXNesuzScGNVsRsmr.png',
        path: '/bankList',
      },
    ],

    //
  },
  created() {
    this.currentRoutePath = this.$route.path;

    //
  },
  methods: {
    // 项 点击
    itemClick(item) {
      this.$router.replace(item.path);
    },

    //
  },
};
</script>

<style lang="scss" scoped>
</style>
